<template>
	<div class="decorateList">
        <van-nav-bar title="装配列表"  left-arrow @click-left="leftReturn" />
        <div class="potential">

                <van-row class="info" type="flex">
                    <van-col span="24" >
                        <p style="float:right;color:#ff5d3b;padding-right:10px;font-size:15px" @click="decorateDetails">+添加项目<span></span></p>
                    </van-col>
                </van-row>

        </div>
        <p class="details" style="margin-left: 15px">{{$route.query.roomName}}间(必填)</p>
        <van-collapse v-model="abc"  @change="toSelect($event)" v-for="(item,index) in decorateNeedList">
            <van-collapse-item  :key="index" :name="index"  :title='item.middlesumName' :value='(item.computeCount?item.computeCount:0)+"项/"+(item.computeMoney?item.computeMoney:0)+"元"'  class="deploy-setting" style="border-bottom-right-radius: 10px">
               <div class="contract" v-for="(little,littleIndex) in item.children" :key="little.id">
                        <div class="title" >{{little.itemName}}</div>
                <div class="potential">
                    <div class="panel">
                        <van-row class="info">
                            <van-col span="24">
                                <p>区域：<span>{{ little.regionalLocationName?little.regionalLocationName:'' }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>金额：<span>{{ little.estimateMoney }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>数量：<span>{{ little.totalCount }}</span></p>
                            </van-col>
                            <van-col span="12" v-if="little.supplierName ">
                                <p>供应商：<span>{{ little.newsupplierName?little.newsupplierName:little.supplierName }}</span></p>
                            </van-col>
                            <van-col span="12" v-if="little.dictionaryTitle">
                                <p>加项原因：<span>{{ little.dictionaryTitle }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>备注：<span>{{ little.remarks }}</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </div>
                        <div class="bottom">
                            <div @click="changeDecorate(little)" class="bottom-changeLease">修改</div>
                            <div @click="deleteDecorate(little,littleIndex,item.children.length)" class="bottom-property" style="float:left">删除</div>
                        </div>
               </div>
            </van-collapse-item>
        </van-collapse>
        <p class="details" style="margin-left: 15px">{{$route.query.roomName}}间(非必填)</p>
        <van-collapse v-model="abc"  @change="toSelect($event)"  v-for="(item,index) in decorateNonessentialList"  >
<!--            <div>-->
            <van-collapse-item v-if="item.requiredFields"  :key="index" :name="index" :title='item.middlesumName' :value='(item.computeCount?item.computeCount:0)+"项/"+(item.computeMoney?item.computeMoney:0)+"元"'  class="deploy-setting" style="border-bottom-right-radius: 10px">
               <div class="contract" v-for="little,littleIndex in item.children" :key="little.id">
                        <div class="title" >{{little.itemName}}</div>
                <div class="potential">
                    <div class="panel">
                        <van-row class="info">
                            <van-col span="24">
                                <p>区域：<span>{{ little.regionalLocationName?little.regionalLocationName:little.roomName }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>金额：<span>{{ little.estimateMoney }}</span></p>
                            </van-col>
                            <van-col span="12">
                                <p>数量：<span>{{ little.totalCount }}</span></p>
                            </van-col>
                            <van-col span="12" v-if="little.supplierName ">
                                <p>供应商：<span>{{ little.newsupplierName?little.newsupplierName:little.supplierName }}</span></p>
                            </van-col>
                            <van-col span="12" v-if="little.dictionaryTitle">
                                <p>加项原因：<span>{{ little.dictionaryTitle }}</span></p>
                            </van-col>
                            <van-col span="24">
                                <p>备注：<span>{{ little.remarks }}</span></p>
                            </van-col>
                        </van-row>
                    </div>
                </div>
                        <div class="bottom">
                            <div @click="changeDecorate(little)" class="bottom-changeLease">修改</div>
                            <div @click="deleteDecorate(little,littleIndex,item.children.length)" class="bottom-property" style="float:left">删除</div>
                        </div>
               </div>
            </van-collapse-item>
<!--            </div>-->
        </van-collapse>
        <!--<van-button class="saveButton_Enable" @click="confirm()" style="margin-bottom: 0px">确认方案</van-button> -->
		<div style="width: 100%;height: 50px;"></div>
        <van-popup class="van-popup--bottom" v-model="decorationShow" position="bottom">
            <div  class="part-inputpart-row" @click="clickArea">
                <div :class="decoration.areaName ? 'content-have' : 'content-none'"></div>
                <span class="part-inputpart-row-header">区域</span>
                <span class="content-divide">|</span>
                <span class='part-inputpart-row-normaltext'>{{ decoration.areaName }}</span>
                <img class="part-inputpart-row-right" :class="areaShow? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
            </div>
          <div  class="part-inputpart-row" @click="toSearch">
            <div :class="decoration.itemName ? 'content-have' : 'content-none'"></div>
            <span class="part-inputpart-row-header">装修项</span>
            <span class="content-divide">|</span>
            <span class='part-inputpart-row-normaltext'>{{ decoration.itemName }}</span>
            <img class="part-inputpart-row-right part-inputpart-row-right-upArrow"  src="../../assets/images/youjiantou.png"/>
          </div>
          <div style="margin-left: 110px;font-size: 12px;color: red" v-if="decoration.itemName&&$route.query.decorationType=='1'">
            <span>库存剩余{{conent}}个</span>
          </div>
          <div class="part-inputpart-row">
              <div :class="decoration.amount ? 'content-have' : 'content-none'"></div>
              <span class="part-inputpart-row-header">数量</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field class="nopadding" :formatter="formatter" type="number" v-model="decoration.amount" placeholder="数量" @input="changeMoney()" :disabled="notEditable&&$route.query.decorationType=='1'"></van-field>
              </div>
          </div>
          <div id="paymentMethodPanel" class="part-inputpart-row" @click="clickSupplier" v-if="$route.query.decorationType=='1'">
            <span :class="decoration.supplierId ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header">供应商</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext">{{ decoration.supplierName }}</span>
            <img class="part-inputpart-row-right" :class="supplierShow? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
          </div>
          <div class="part-inputpart-row">
              <div :class="decoration.price ? 'content-have' : 'content-none'"></div>
              <span class="part-inputpart-row-header">金额</span>
              <span class="content-divide">|</span>
              <div class="nopadding">
                <van-field class="nopadding" :formatter="formatter" type="number" v-model="decoration.price" placeholder="租金" ></van-field>
              </div>
          </div>
          <div id="paymentMethodPanel" class="part-inputpart-row" @click="clickAddReason" v-if="(!notEditable&&$route.query.decorationType=='1'&&parseInt(decoration.amount)+haveAddCount>decoration.roomHaveSum)||(notEditable&&decoration.addreasonId)">
            <span :class="decoration.addreasonId ? 'content-have' : 'content-none'"></span>
            <span class="part-inputpart-row-header">加项原因</span>
            <span class="content-divide">|</span>
            <span  class="part-inputpart-row-normaltext">{{ decoration.addreasonName }}</span>
            <img class="part-inputpart-row-right" :class="decoration.addreasonName? 'part-inputpart-row-right-upArrow': 'part-inputpart-row-right-downArrow'" src="../../assets/images/triangle.png"/>
          </div>
          <div class="part-inputpart-row-vedio" v-if="(!notEditable&&$route.query.decorationType=='1'&&parseInt(decoration.amount)+haveAddCount>decoration.roomHaveSum)||(notEditable&&decoration.addreasonId)">

              <div :class="imageList.length>0 ? 'content-have' : 'content-none'" style="position:absolute;top:-2px"></div>
              <div style="position:absolute;left:45px;top:-8px;font-weight:900;font-size:12px">加项证据</div>
                <div class="upLoadImg" >
                    <van-uploader v-model="imageList" multiple
                                  accept="image/*"
                                  preview-size="80px"
                                  :after-read="afterReadTest"
                                  style="padding-left: 0.25rem"/>
          </div>
        </div>
          <div class="addPropertyDetail">
            <textarea class="textareaRemarks" placeholder="备注" v-model="decoration.remarks"></textarea>
          </div>
          <div style="width: 100%;padding: 15px">
        <van-button class="saveButton_Enable"
                @click="save">保存
        </van-button>
          <van-button class="closeButton_Enable"
                      @click="close">取消
          </van-button>
          </div>
        </van-popup>
        <van-popup v-model="supplierShow" position="bottom">
          <div class="searchDiv">
            <van-search
                placeholder="请输入"
                v-model="supplierName"
                size="large"
                @input="getSupplierList()"
            />
          </div>
            <van-picker
              show-toolbar
              value-key="supplierName"
              :columns="supplierList"
              @cancel="supplierShow = false"
              @confirm="confirmSupplier"
            />
        </van-popup>
        <van-popup v-model="areaShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="regionalLocationValue"
              :columns="areaList"
              @cancel="areaShow = false"
              @confirm="confirmArea"
            />
        </van-popup>
        <van-popup v-model="addreasonShow" position="bottom">
            <van-picker
              show-toolbar
              value-key="dictionaryTitle"
              :columns="addreasonList"
              @cancel="addreasonShow = false"
              @confirm="confirmAddReason"
            />
        </van-popup>
	</div>
</template>

<script>
	import {
		NavBar,
		Picker,
		List,
		PullRefresh,
		Toast,
		Popup,
        Row,
        Col,Dialog,Button,Collapse,
        CollapseItem,
        Uploader,
        Field,
    Search,
	} from "vant";
    Vue.use(Field);
	Vue.use(Picker);
	Vue.use(List);
	Vue.use(PullRefresh);
	Vue.use(Popup);
	Vue.use(PullRefresh);
	import {
        queryGoodClassisRequired,queryGoodsCount,queraddGoodsallocation,delGoodsallocation,verificationGoodsallocation,addGoodsallocation,querySupplierList,queryDictionary,base64Upload,queryisPament,findHouseArea
	} from "../../getData/getData";
	import {
		responseUtil,
         getStaffId,
	} from "../../libs/rongxunUtil";
	import rxNavBar from "../../components/rongxun/rx-navBar/navBar";
	import Vue from 'vue';
	import dialMobile from '../../components/rongxun/rx-dialMobile/dialMobile'
    import eventBus from "../../components/rongxun/rx-navBar/envbus.js"
	export default {
		name: "decorat",
		data() {
			return {
			  conent:'',
                decorateAllList:[],    //装修所有项列表
                decorateNeedList:[],    //装修必填项列表
                decorateNonessentialList:[],    //装修选填列表
                littleDecoration:[],    //小项
                decorationShow:false,
                abc:[],
                decorateList:[],    //装修必填项列表
                supplierShow:false,    //供应商列表弹出/隐藏
                supplierList:[],    //供应商列表
                decoration:{areaId:'',areaName:'',itemName:'',amount:'',supplierId:'',supplierName:'',price:'',warrantyPeriod:'',remarks:'',picList:[],add_id:'',addreasonId:'',addreasonName:''},
                unitPrice:0,    //装修项单价
                addreasonList:[],       //加项原因集合
                addreasonShow:false,    //加项原因列表弹出/隐藏
                imageList:[],
                saveingStatus: false,   //控制保存按钮状态，防止二次点击  默认为保存，可点击
                haveAddCount:0,     //中项已经添加过的项目数量
                notEditable:false,     //false可编辑，true不可编辑
                areaShow:false,  //区域列表弹出/隐藏
                areaList:[],   //区域列表
                supplierName:'',  //供应商
			}
		},
		components: {
			[NavBar.name]: NavBar,
			[List.name]: List,
			[PullRefresh.name]: PullRefresh,
            [Row.name]: Row,
            [Col.name]: Col,
            [Button.name]: Button,
            [Dialog.name]:Dialog,
			dialMobile,
			rxNavBar,
            [Collapse.name]: Collapse,
            [CollapseItem.name]: CollapseItem,
            [Uploader .name]:Uploader,
      [Search.name]: Search,

    },
		created() {
		},
		mounted() {
            this.getGoodsList()
            this.getQueryDictionary()
            this.getSupplierList()
            this.getAreaList()
		},
		methods: {
      queryGoodsCount(e){
        let that=this
        let initData={}
        initData.tgId = e.toString()
        queryGoodsCount(initData).then(function (response) {
          responseUtil.dealResponse(that, response, () => {
            let conent = 0
            let quantity = 0
            let issueInTransit = 0
            if(response.data.data.data.length == 0){
              console.log(3333)
              that.conent = 0
            }else{
              console.log(1222)
              for (let i = 0; i < response.data.data.data.length; i++) {
                quantity += response.data.data.data[i].quantity
                issueInTransit += response.data.data.data[i].issueInTransit
              }
              conent = quantity-issueInTransit
              that.conent = conent
            }
            console.log(conent)


          })
        })
      },

            saveAble() {
                let that=this
                if (that.decoration.itemName&&    //区域不为空 装修项存在
                that.decoration.amount>0&&  //装修金额大于0
                (that.$route.query.decorationType=='0'||that.decoration.supplierId)&&   //decorationType硬装0，软装1（为硬装 或存在装修项）
                ((!that.notEditable&&that.$route.query.decorationType=='1'&&parseInt(that.decoration.amount)+that.haveAddCount>that.decoration.roomHaveSum&&that.decoration.addreasonName&&that.imageList.length>0)||  //新增 且软装 且钱够 且有地址 且有图片  或
                (that.notEditable&&that.$route.query.decorationType=='1'&&parseInt(that.decoration.amount)+that.haveAddCount>that.decoration.roomHaveSum)&&that.decoration.addreasonName&&that.imageList.length>0)  ||  //修改 且软装 且钱够 且有地址 且有图片     ///或
                parseInt(that.decoration.amount)+that.haveAddCount<=that.decoration.roomHaveSum||  //或硬装 钱小于
                that.$route.query.decorationType=='0'){  //必填项都填了
                    return true
                }else{
                    return false
                }
            },
            toSelect(e){
                return
                this.$router.push({
                    name:'decorateSearch',
                    query:{
                        id:this.$route.query.id,
                        sethouse_id:this.$route.query.setHouse_id,
                        decorationType:this.$route.query.decorationType,
                        type:"decorateList"
                    }
                })
            },
            //修改
            changeDecorate(e){
                console.log(e)
                e.add_id=e.id
                e.goodsallocationId=e.goodsallocationId
                e.amount=e.totalCount
                e.supplierId=e.supplier_id
                e.type='change'
                this.unitPrice=Number(e.price)
                this.decorateDetails(e)
            },
            //删除
            deleteDecorate(e,index,length){
                //console.log(index)
                //console.log(length)
                /*if(this.$route.query.decorationType=='1'&&index+1==length&&length!=1){
                    responseUtil.alertMsg(this,"同一类物品存在一个加项物品，请先删除加项物品")
                    return
                }*/
                /*if(!(this.$route.query.decorationType=='0'||(index==0&&length>=1))){
                    responseUtil.alertMsg(this,"同一类物品存在一个加项物品，请先删除加项物品")
                    return
                }*/
                Dialog.confirm({
                    title: '友情提示',
                    message: '确定删除吗？',
                }).then(() => {
                    var that = this
                    let initData = {}
                    initData.delGoodsList=[{id:e.id}]
                    delGoodsallocation(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            responseUtil.alertMsg(that,'删除成功')
                            that.getGoodsList()
                        })
                    })
                })
                .catch(() => {
                });
            },
            //添加明细
            decorateDetails(e){
                console.log(e)
                if(!e.id){
                    this.imageList=[]
                    this.notEditable=false
                    this.decoration={areaId:'',areaName:'',itemName:'',amount:'',supplierId:'',supplierName:'',price:'',warrantyPeriod:'',remarks:'',picList:[],add_id:'',addreasonId:'',addreasonName:''}
                }else{
                    this.notEditable=true
                    this.decoration=e
                    this.decoration.addreasonId=e.reasonAddition
                    this.decoration.addreasonName =e.dictionaryTitle
                    this.decoration.price=e.estimateMoney
                    this.decoration.supplierName=e.newsupplierName
                    this.decoration.areaName = e.regionalLocationValue
                    if(!this.decoration.picList){
                        this.imageList=[]
                    }
                        this.imageList=this.decoration.picList

                    for(let i in this.imageList){
                        this.imageList[i].url=this.imageList[i].path
                    }
                }
                this.decorationShow=true
            },
            //获取必填大项
            getGoodsList(){
                let that=this
                let initData={}
                initData.itemType=this.$route.query.decorationType
                queryGoodClassisRequired(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.decorateAllList=response.data.data.data
                    that.decorateNeedList=[]
                    that.decorateNonessentialList=[]
                    for(let i in that.decorateAllList){
                        that.decorateAllList[i].children=[]
                        if(that.decorateAllList[i].isRequired=='是'){
                            that.decorateNeedList.push(that.decorateAllList[i])
                        }else if(that.decorateAllList[i].isRequired=='否'){
                            that.decorateNonessentialList.push(that.decorateAllList[i])
                        }
                        that.abc.push(that.decorateAllList[i].middlesumName)
                    }
                    that.selectProgram()
                  })
                })
            },
            selectProgram(){
                let that=this
                let initData={}
                initData.ar_id=this.$route.query.arid
                initData.sethouse_id=this.$route.query.setHouse_id
                initData.room_id=this.$route.query.roomId
                initData.itemType=this.$route.query.decorationType
                queraddGoodsallocation(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    console.log(response)
                    that.littleDecoration=response.data.data.data
                    for(let i in that.littleDecoration){
                        for(let j in that.decorateNeedList){
                            if(that.littleDecoration[i].goodsallocationmiddlesumId==that.decorateNeedList[j].goodsallocationmiddlesumId){
                                    that.decorateNeedList[j].requiredFields=true
                                    that.decorateNeedList[j].children.unshift(that.littleDecoration[i])
                                    that.decorateNeedList[j].computeCount=0
                                    that.decorateNeedList[j].computeMoney=0
                                    break
                            }
                        }
                        for(let j in that.decorateNonessentialList){
                            if(that.littleDecoration[i].goodsallocationmiddlesumId==that.decorateNonessentialList[j].goodsallocationmiddlesumId){
                                    that.decorateNonessentialList[j].requiredFields=true
                                    that.decorateNonessentialList[j].children.unshift(that.littleDecoration[i])
                                    that.decorateNonessentialList[j].computeCount=0
                                    that.decorateNonessentialList[j].computeMoney=0
                                    break
                            }
                        }
                    }
                    for(let i in that.decorateNeedList){
                        for(let j in that.decorateNeedList[i].children){
                            that.decorateNeedList[i].computeCount+=that.decorateNeedList[i].children[j].totalCount
                            that.decorateNeedList[i].computeMoney+=that.decorateNeedList[i].children[j].estimateMoney
                        }
                    }
                    for(let i in that.decorateNonessentialList){
                        for(let j in that.decorateNonessentialList[i].children){
                            that.decorateNonessentialList[i].computeCount+=that.decorateNonessentialList[i].children[j].totalCount
                            that.decorateNonessentialList[i].computeMoney+=that.decorateNonessentialList[i].children[j].estimateMoney
                        }
                    }
                    console.log(that.decorateNeedList)
                    console.log(that.decorateNonessentialList)
                    that.$forceUpdate()
                  })
                })
            },
            //确认方案
            confirm(){
                let that=this
                let initData={}
                initData.afterRent_id=this.$route.query.arid
                if(this.$route.query.decorationType=='0'){
                    initData.submitHardList=that.littleDecoration
                    initData.itemType='0'
                }else if(this.$route.query.decorationType=='1'){
                    initData.submitSoftList=that.littleDecoration
                    initData.itemType='1'
                }
                console.log(initData)
                verificationGoodsallocation(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertMsg(that,"确认成功")
                        that.leftReturn()
                    })
                })
            },
            save(){
                if(!this.saveAble()){
                    responseUtil.alertMsg(that,"必填项没填")
                     return
                }
                let that=this
                let initData={}
                initData.staff_id=getStaffId()
                initData.afterRent_id=this.$route.query.arid
                if(this.$route.query.decorationType=='0'){
                  initData.submitHardList=[{
                    id:this.decoration.goodsallocationId?this.decoration.goodsallocationId:this.decoration.id,
                    count:this.decoration.amount,
                    roomId:this.$route.query.roomId,
                    estimateMoney:this.decoration.price,
                    remarks:this.decoration.remarks,
                    supplier_id:this.decoration.supplierId,
                    add_id:this.decoration.add_id,
                    areaId:this.decoration.areaId,
                  }]
                }else if(this.$route.query.decorationType=='1'){
                  initData.submitSoftList=[{
                    id:this.decoration.goodsallocationId?this.decoration.goodsallocationId:this.decoration.id,
                    count:this.decoration.amount,
                    roomId:this.$route.query.roomId,
                    estimateMoney:this.decoration.price,
                    remarks:this.decoration.remarks,
                    supplier_id:this.decoration.supplierId,
                    reasonAddition:this.decoration.addreasonId,
                    add_id:this.decoration.add_id,
                    picList:this.imageList,
                    areaId:this.decoration.areaId,
                  }]
                }
                console.log(initData)
                that.saveingStatus = true
                addGoodsallocation(initData).then(function (response) {
                    that.saveingStatus = false
                    responseUtil.dealResponse(that, response, () => {
                        responseUtil.alertOK(that,"保存成功")
                        that.decorationShow=false
                        that.imageList=[]
                        that.getGoodsList()
                  })
                })

            },
            close(){
              this.decorationShow=false
              this.decoration = {}
            },
            changeMoney(){
            if(this.notEditable){
                return
            }
              let m = this.getDecimalPlaces(this.decoration.amount)
              let n = this.getDecimalPlaces(this.unitPrice)
              // 转换为整数
              let intA = Math.round(this.decoration.amount * Math.pow(10, m));
              let intB = Math.round(this.unitPrice * Math.pow(10, n));
              this.decoration.price=Number((intA * intB) / Math.pow(10, m+n))

            },
            getDecimalPlaces(num) {
              // 将数字转换为字符串
              const str = num.toString();
              // 查找小数点
              const decimalIndex = str.indexOf('.');
              // 如果小数点不存在，返回0
              if (decimalIndex === -1) return 0;
              // 返回小数位数，即字符串长度减去小数点之前的位数
              return str.length - decimalIndex - 1;
            },
            //点击区域，弹出下拉框
            clickArea(){
                this.areaShow = !this.areaShow
            },
            //选择区域
            confirmArea(item){
                this.decoration.areaId=item.id
                this.decoration.areaName=item.regionalLocationValue
                this.areaShow=false
            },
            //点击供应商，弹出下拉框
            clickSupplier(){
                this.supplierName = ''
               this.supplierShow=!this.supplierShow
            },
            //选择供应商
            confirmSupplier(item){
               this.decoration.supplierId=item.id
               this.decoration.supplierName=item.supplierName
               this.supplierShow=false
            },
            clickAddReason(){
                this.addreasonShow=!this.addreasonShow
            },
            confirmAddReason(item){
               this.decoration.addreasonId=item.id
               this.decoration.addreasonName=item.dictionaryTitle
               this.addreasonShow=false
            },
            //前往查询页面
            toSearch(){
                if(this.notEditable){
                    return
                }
                if(this.$route.query.type=='change'){
                    return
                }
                this.$router.push({
                        name:'decorateSearch',
                        query:{
                            id:this.$route.query.id,
                            sethouse_id:this.$route.query.setHouse_id,
                            //arid:this.$route.query.arid,
                            decorationType:this.$route.query.decorationType,
                            type:"decorateList",
                            areaName:this.decoration.areaName,
                            areaId:this.decoration.areaId
                            //roomHouse_id:this.$route.query.roomId
                    }
                })
            },
            //查询区域列表
            getAreaList(){
                let that = this
                let initData = {}
                initData.setHouse_id = this.$route.query.setHouse_id
                // if(this.$route.query.roomId!='-1'){
                    initData.roomHouse_id = this.$route.query.roomId
                // }
                findHouseArea(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        console.log(response.data.data.data)
                        that.areaList=response.data.data.data
                    })
                })
            },
            //查询供应商列表
            getSupplierList(){
                let that=this
                let initData={}
                initData.supplierName = that.supplierName
                querySupplierList(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.supplierList=response.data.data.data
                        // console.log(that.supplierList)
                        if(that.$route.query.supplierId){
                            that.decoration.supplierId=that.$route.query.supplierId
                            that.decoration.supplierName=that.$route.query.supplierName
                        }
                    })
                })
            },
            //输入框的公共方法，过滤掉空格
            formatter(value) {
                return value.replace(/\s*/g, '')
            },
            afterReadTest(file){
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        this.afterRead(fileDetail,i,fileLength);
                    }
                }else{
                    this.afterRead(file);
                }
            },
            afterRead(file,i,fileLength) {
                let that = this
                let initData = {
                    base64 : file.content
                }
                that.loadingFlag = true;
                base64Upload(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        var image={path:response.data.data.path,url:response.data.data.path}
                        for(let i in that.imageList){
                             if(!that.imageList[i].path){
                                 that.imageList.splice(i,1)
                             }
                        }
                        that.imageList.push(image)
                    })
                })
            },
            //加项原因列表
            getQueryDictionary(){
                let that=this
                let initData={}
                initData.dictionaryClassName='addItemreason'
                queryDictionary(initData).then(function (response) {
                  responseUtil.dealResponse(that, response, () => {
                    that.addreasonList=response.data.data.data
                  })
                })
            },
            //是否超过最大拥有数量
            moreBiggestCount(){
               this.haveAddCount=0
               for(let i in this.littleDecoration){
                   if(this.decoration.t_goodsallocationmiddlesumId==this.littleDecoration[i].goodsallocationmiddlesumId){
                        this.haveAddCount+=this.littleDecoration[i].totalCount
                   }
               }
            },
            leftReturn(){
                eventBus.$emit('decorationList','decorationList')
                this.$router.go(-1);
            },
		},
        activated() {
            eventBus.$on('decorationChoice',function(data) {
              console.log(data)
                if(data.id){
                    this.decoration=data
                    this.decoration.id=data.id
                    this.decoration.amount=1
                    this.decoration.picList=[]
                    this.decoration.supplierId=data.supplier_id
                    this.decoration.areaName = data.areaName
                    this.decoration.areaId = data.areaId
                    this.unitPrice=data.price
                    if(!this.decoration.roomHaveSum){
                        this.decoration.roomHaveSum=10000000
                    }
                    this.moreBiggestCount()
                    this.queryGoodsCount(data.id)
                }

            }.bind(this))
        },
        //如果前往的页面不是推荐人，则摧毁缓存
        beforeRouteLeave: function(to, from, next) {
    if (from && to.name != 'decorateSearch') {
      //此处判断是如果返回上一层，你可以根据自己的业务更改此处的判断逻辑，酌情决定是否摧毁本层缓存。
      if (this.$vnode && this.$vnode.data.keepAlive) {
        if (
          this.$vnode.parent &&
          this.$vnode.parent.componentInstance &&
          this.$vnode.parent.componentInstance.cache
        ) {
          if (this.$vnode.componentOptions) {
            var key =
              this.$vnode.key == null
                ? this.$vnode.componentOptions.Ctor.cid +
                  (this.$vnode.componentOptions.tag
                    ? `::${this.$vnode.componentOptions.tag}`
                    : '')
                : this.$vnode.key
            var cache = this.$vnode.parent.componentInstance.cache
            var keys = this.$vnode.parent.componentInstance.keys
            if (cache[key]) {
              if (keys.length) {
                var index = keys.indexOf(key)
                if (index > -1) {
                  keys.splice(index, 1)
                }
              }
              delete cache[key]
            }
          }
        }
      }
      this.$destroy()
    }
    next()
        },
        beforeDestroy() {
          eventBus.$off('decorationList')
        }

	}
</script>

<style scoped lang="less">
    @redius: 8px;
    .details {
        font-size: 12px;
        font-weight: 900;
        color: #F76243;
        margin-bottom: 5px;
        margin-left: 0px;
    }

    .content-have {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #ff5d3b;
    }

    .content-none {
        padding: 3px;
        margin: 0 10px;
        border-radius: 50%;
        background-color: #c7c7c7;
    }
    .contract{
        position: relative;
        height: auto;
        background-color: white !important;
        border-radius: 8px;
        overflow: hidden;
        //margin-bottom:10px;
        padding:10px 10px 0 10px;
        margin-bottom:10px
    }
    .position{
        height: auto;
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        white-space:nowrap;
    }
    .contract-info{
        width: 100%;
        height: auto;
        padding: 15px 0 10px 0;
        border-bottom: 1px solid #e0e0e0;
    }
    .contract-text{
        display:inline-block;
        width:40%;
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
        white-space:nowrap;
    }
    .contract-textLong{
        display:inline-block;
        width:100%;
        height: 25px;
        line-height: 25px;
        text-align: left;
        margin-left: 20px;
        color: #999999;
        font-size: 14px;
        white-space:nowrap;
    }
    .bottom{
        //width: 330px;
        height: 60px;
        //padding-right: 15px;
        overflow: hidden;
    }
    .bottom-changeLease{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-right: 11px;
        line-height: 30px;
        background: linear-gradient(to right,#7cc7ff,#3596fd);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .bottom-property{
        float: right;
        width: 90px;
        height: 30px;
        margin-top: 15px;
        margin-left: 11px;
        right: 15px;
        line-height: 30px;
        background: linear-gradient(to right,#ffbe72,#ff6c41);
        color: white;
        font-size: 12px;
        text-align: center;
        border-radius: 8px;
    }
    .saveButton_Disable {
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 50px;
        border-radius: 8px;
        margin: 25px 15px 80px;
        font-size: 18px;
        color: white;
        line-height: 1.22rem;
        text-align: center;
    }
    .saveButton_Enable {
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;
        color: white;
        width: 92%;
        border-radius: 8px;
        font-size: 18px;
      margin-bottom: 10px;

    }
    .closeButton_Enable{
      background-color: #3B98FF;
      color: white;
      width: 92%;
      border-radius: 8px;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .part-inputpart-row {
       display: flex;
       flex-direction: row;
       border-radius: 8px;
       align-items: center;
       height: 45px;
       background-color: #ffffff;
       margin-top: 15px;
    }
    .part-inputpart-row-vedio {
        position:relative;
       display: flex;
       flex-direction: row;
       border-radius: 8px;
       align-items: center;
       background-color: #ffffff;
       margin-top: 30px ;
    }
    .part-inputpart-row-header {
        margin-left: 26px;
        font-weight: 900;
        font-size:12px;
    }
.content-divide {
  font-size: 12px;
  color: #efefef;
  margin: 0 10px;
}
.part-inputpart-row-graytext {
  width: 100px;
  color: #d8d8d8;
  flex: auto;
}
.part-inputpart-row-normaltext {
  color: black;
  flex: auto;
  font-size:12px;
}
.part-inputpart-row-right {
  float: right;
  margin-right: 10px;
}

.part-inputpart-row-right-downArrow {
  width: 9px;
}

.part-inputpart-row-right-upArrow {
  width: 9px;
  transform: rotateX(180deg);
}
    .upLoadImg{
        background: white;
        margin:30px 30px 0 30px;
        padding:10px;
    }
    .remake{
        color: gray;
        width: 310px;
        border-radius: 10px;
        line-height: 20px;
        height: 70px;
        margin: 5px 10px 0 10px;
        background-color: #f8f8f8;
        font-size: 12px;
        border: none;
        padding-left: 10px;
        padding-top: 10px;
    }
    .addPropertyDetail{
        width: 100%;
        margin: 0.25rem auto 0.25rem;
        background-color: white;
        border-radius: 0.21333rem;
        padding-bottom: 0.05rem;
        padding-top: 0.05rem;
        position:relative;
        /*    备注文本域*/
        .textareaRemarks{
            width: 82%;
            height: 50px;
            margin: 15px auto;
            border-radius: 8px;
            display: block;
            background-color: #f7f7f7;
            border: none;
            font-size: 13px;
            color: #999;
            padding: 15px 15px;
            resize: none;
        }
    }
			.title {
				font-weight: bolder;
				color: rgb(0, 0, 0);
				font-size: 15px;
				/*padding: 20px 15px 15px;*/
				/*border-bottom: 1px solid rgb(250, 250, 250);*/

				span {
					font-weight: normal;
					color: rgba(153, 153, 153);
				}
			}
    .potential {
        width: 100%;
        /*background-color: #F8F8F8;*/

        box-sizing: border-box;
        overflow: hidden;
        color: #252525;


        .sub_btn {
            margin: 30px 0 70px;

            .sub_button {
                border-radius: @redius;
                color: rgb(255, 255, 255);
                border: none;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));

                &.not_choose {
                    background: none;
                    background-color: rgba(184, 184, 184, 0.2);
                }
            }
        }

        p {
            margin: 0px;
        }

        .application_time {
            text-align: right;
            width: 345px;
            font-size: 12px;
            color: rgb(136, 136, 136);
            padding: 2px 0 5px;
        }

        .subtitle {
            padding: 5px 0px;
            color: rgb(244, 99, 76);
            margin-top: 25px;
            font-weight: 700;
            font-size: 12px;
        }

        .overDiv {
            height: 23px;
            /*line-height: 10px;*/
            /*border: 1px solid red;*/
            display: flex;
            align-items: center;
            /*justify-content: center;*/
        }

        /*实际结束更改*/

        .overDiv > div {
            font-weight: 900;
        }

        .overDiv > span {
            font-size: 13px;
            color: rgb(110, 110, 110);
        }

        .calendar_icon {
            display: inline-block;
            width: 18px;
            height: 18px;
            background: url(../../assets/images/timepick.png) no-repeat center center;
            background-size: cover;
            margin-left: 10px;
            background-position-y: -1px;
        }

        .panel {
            width: 100%;
            /*background-color: rgb(255, 255, 255);*/

            position: relative;
            font-size: 14px;
            text-align: left;
            overflow: hidden;
            border-bottom: 1px solid #e0e0e0;

            &.withMargin {
                margin: 15px 0;
            }

            & > p {
                padding: 15px 15px 0px;
                font-weight: 900;
                /*color: #252525;*/

            }

            & > p > span {
                font-weight: normal;
                color: rgb(190, 190, 190);
            }

            .state {
                position: absolute;
                top: 0;
                left: 0;
                font-size: 20px;
                font-weight: 900;
                height: 32px;
                line-height: 32px;
                width: 60px;
                display: block;
                background: linear-gradient(rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                background: -webkit-linear-gradient(left, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Safari 5.1 - 6.0 */
                background: -o-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Opera 11.1 - 12.0 */
                background: -moz-linear-gradient(right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                /* Firefox 3.6 - 15 */
                background: linear-gradient(to right, rgba(255, 194, 116, 1), rgba(255, 93, 59, 1));
                border-top-left-radius: 16px;
                border-bottom-right-radius: 16px;
                color: rgb(255, 255, 255);
                text-align: center;
                transform: scale(0.5);
                transform-origin: 0% 0%;
                font-family: sans-serif;
                padding: 0 10px;
            }

            .title {
                width: 315px;
                padding: 0 15px;
                font-weight: 900;
                color: rgb(0, 0, 0);
                font-size: 16px;
                padding-top: 20px;
                span {
                    font-size: 14px;
                    font-weight: normal;
                    color: rgba(153, 153, 153, 1);
                }
            }

            .allprice {
                padding-bottom: 4px;
            }

            /*审核结果,服务人员 审批意见*/

            .shenhe, .fuwu, .yijian {
                font-size: 15px;
            }

            .describe {
                padding-bottom: 15px;
                padding-top: 10px;
                color: rgb(82, 82, 82);
                font-weight: normal;
            }

            .info {
                padding: 15px;


                &.noPadding {
                    padding: 0 15px;
                }

                p {
                    margin: 4px 0;
                    font-weight: 900;

                    span {
                        font-size: 13px;
                        font-weight: normal;
                        color: rgb(110, 110, 110);
                    }
                }

                .floatR {
                    float: right;
                }

                .total_color {
                    color: rgba(255, 93, 59, 1);
                }
            }

            .text_body {
                padding: 15px;

                .mes_body {
                    border-radius: @redius;
                    background-color: rgb(250, 250, 250);
                }
            }
        }


        .radio_body {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            align-items: center;

            .dot {
                display: inline-block;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                background-color: rgb(136, 136, 136);
                vertical-align: middle;
                margin-right: 10px;
                margin-bottom: 6px;

                &.is_dot {
                    background-color: rgb(251, 91, 64);
                }
            }

            .line {
                width: 2px;
                height: 12px;
                background-color: rgb(250, 250, 250);
            }

            .choose {
                margin-right: 20px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 15px;

                .choose_btn {
                    padding: 5px;
                    margin: 10px;
                    color: rgb(136, 136, 136);
                    cursor: pointer;

                    &.pass {
                        color: rgb(251, 91, 64);
                        font-weight: 900;
                    }

                    &.reject {
                        color: red;
                        font-weight: 900;
                    }
                }
            }
        }


        .t_table {
            .t_header {
                text-align: center;
                color: rgb(136, 136, 136);
                /*font-weight: 900;*/
                font-size: 10px;
                height: 40px;
                line-height: 40px;
            }

            .t_title {
                background-color: rgb(244, 249, 255);
                color: #33ADFF;
                text-align: center;
                font-weight: 700;
                font-size: 12px;
                height: 20px;
            }

            .t_td {
                text-align: center;
                height: 40px;
                line-height: 40px;
                font-size: 13px;
            }
        }

        .displayBlock {
            //position: relative;
            overflow: hidden;
            background-color: white;
            border-radius: 9px;
            width: 9rem;
            height: auto;
            margin: 19px auto;
        }

        .swiper_show {
            width: 325px;
            position: relative;
            margin: 15px 10px;
            height: 75px;

            .content {
                position: absolute;
                display: flex;
                justify-content: space-around;
                left: 0px;

                .imgWrap {
                    margin-right: 10px;
                }
            }

            /*设计图片部分的css*/

            .part {
                background-color: white;
                border-radius: 8px;
                margin: 15px;

            }


            /*css*/

            .part-subtitle {
                color: #ff5d3b;
                font-size: 12px;
                font-weight: 900;
                position: relative;
                top: 12px;
            }

            /*css*/

            .part-inputpart {
                display: flex;
                flex-direction: column;
                white-space: nowrap;
                overflow: hidden;
                font-size: 15px;
            }


            /*css*/

            .part-uploadpic-module {
                margin: 10px 25px 10px 10px;
            }

            /*css*/

            .part-uploadpic-text {
                font-size: 15px;
                margin-left: 24px;
            }

            /*css*/

            .part-uploadpic-module-pic {
                width: 80px;
                height: 80px;
                border-radius: 8px;
            }


            // 图片墙
            .swiper_show01 { //参数
                width: 9rem;
                position: relative;
                margin: 10px 10px;
                height: 78px;

                .content01 {
                    position: absolute;
                    display: flex;
                    justify-content: space-around;
                    left: 0px;

                    .imgWrap01 {
                        margin-right: 10px;
                    }
                }

                .num01 {
                    position: absolute;
                    left: 8px;
                    top: 15px;
                    font-size: 10px;
                    background-color: rgb(0, 0, 0);
                    opacity: .2;
                    color: rgb(255, 255, 255);
                    padding: 2px 6px;
                    display: inline-block;
                    border-radius: 10px;
                    font-weight: 900;
                    line-height: 10px;
                }
            }

            .displayBlock01 { //参数
                //position: relative;
                overflow: hidden;
                background-color: white;
                border-radius: 9px;
                width: 9rem;
                height: auto;
                margin: 19px auto;
            }


            /*tab部分*/
            // 循环表单留着
            .t_table {
                .t_header {
                    text-align: center;
                    color: rgb(136, 136, 136);
                    font-weight: 900;
                    font-size: 10px;
                    height: 40px;
                    line-height: 40px;
                }

                .t_title {
                    background-color: rgb(244, 249, 255);
                    color: rgb(39, 135, 248);
                    text-align: center;
                    font-weight: 400;
                    font-size: 14px;
                }

                .t_td {
                    text-align: center;
                    height: 40px;
                    line-height: 40px;
                }

                /*tab关联部分css*/

                .fuck {
                    margin: 15px;
                }

                /*css*/

                .part-inputpart {
                    display: flex;
                    flex-direction: column;
                    border-radius: 8px;
                    white-space: nowrap;
                    overflow: hidden;
                    font-size: 15px;
                }

                .part-inputpart-dropDownList-option-selected {
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 40px;
                    background-color: #ffffff;
                    margin-top: 2px;
                }

                /*参数*/

                .part-inputpart-row-header {
                    font-weight: 900;
                    font-size:12px;
                }


                .part-inputpart-row input {
                    border: 0;
                    //width: 100%;
                    flex: auto;
                }


                /*css参数*/

                .part-inputpart-dropDownList {
                    height: 23%;
                    /*垂直方向滚动*/
                    /*overflow-y: scroll;*/
                    overflow: auto;
                    width: 92.5%;
                    position: absolute;
                    z-index: 99;
                }

                /*css参数*/

                .part-inputpart-dropDownList-option {
                    padding: 0;
                    margin: 0;
                    text-align: center;
                    line-height: 30px;
                    background-color: #f5f5f5;
                    font-size: 12px;
                    border-bottom: 1px white solid;
                    color: #999999;
                }


                /*参数*/

                .part-inputpart-row-enablenon {
                    margin-left: 26px;
                }

                /*参数*/

                .part-inputpart-row-graytext {
                    color: #d8d8d8;
                    //width: 100%;
                    flex: auto;
                }

                .part-inputpart-row-normaltext {
                    color: black;
                    flex: auto;
                    font-size:12px;
                }

                .part-inputpart-row-redtext {
                    //width: 100%;
                    flex: auto;
                    color: #ff5d3b;
                }


                /*参数*/

                .part-inputpart-row-right {
                    float: right;
                    margin-right: 10px;
                }

                .part-inputpart-row-right-rightArrow {
                    width: 8px;
                }

                .part-inputpart-row-right-downArrow {
                    width: 9px;
                }

                .part-inputpart-row-right-upArrow {
                    width: 9px;
                    transform: rotateX(180deg);
                }

                .part-inputpart-row-right-timepick {
                    width: 20px;
                }

                .content-none {
                    padding: 3px;
                    margin: 0 10px;
                    border-radius: 50%;
                    background-color: #c7c7c7;
                }

                /*参数*/

                .content-divide {
                    font-size: 12px;
                    color: #efefef;
                    margin: 0 10px;
                }

            }

        }
    }
    .nopadding{

    }
</style>
<style>
   .decorateList .van-cell{
        background-color:#f8f8f8 !important;
    }
    .decorateList .van-collapse-item__content{
        background-color:#f8f8f8 !important;

    }
   .decorateList .nopadding .van-cell{
        background-color:white !important;
    }
</style>

